.cu-navbar-main {
    width: 100vw;
    height: 10dvh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #00000033;
    padding: 0 30px 0 20px;
    margin-bottom: 10px;
    /* position: fixed;
  top: 0%; */
}

::-webkit-scrollbar {
    display: none;
}

.cu-navbar-logo img {
    width: 130px;
    height: 8vh;
}

.cu-navbar-link {
    display: flex;
    align-items: center;
    gap: 60px;
    color: #3f3f3f;
    font-weight: 400;
}

.cu-navbar-link span:hover {
    cursor: pointer;
    font-weight: 700;
}

.cu-navbar-link-active {
    font-weight: 700;
}

.cu-navbar-profile {
    display: flex;
    align-items: center;
    gap: 10px;
}

.cu-navbar-profile img {
    width: 30px;
    height: 30px;
}

.cu-nav-profile-username {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.navbar-view-profile {
    display: flex;
    align-items: center;
    border-top: 0.5px solid #00000033;
    gap: 15px;
    padding: 15px 20px;
}

.navbar-view-profile img {
    width: 20px;
    height: 20px;
}

.navbar-family-options {
    background-color: #ffffff;
    position: fixed;
    top: 10%;
    right: 0.5%;
    box-shadow: 2px 2px 8px 0px #00000026;
    z-index: 999;
    /* height: 270px;
    overflow: scroll; */
}

.navbar-family-option {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    width: 230px;
}

.navbar-family-option img {
    width: 30px;
    height: 30px;
}

.navbar-family-option:hover {
    cursor: pointer;
}

.navbar-view-profile {
    cursor: pointer;
}

.nav-view-profile-btn:hover {
    cursor: pointer;
}
