.cu-top-bar-main {
    width: 100%;
    background-color: #ffffff;
    padding: 0 16px;
    border-bottom: 1px solid #cccccc;
}

.cu-top-bar-cufrontlogo {
    width: 70px;
    height: 50.38px;
}

.cu-top-bar-cufrontlogo img {
    width: 100%;
    height: 100%;
}

.cu-top-bar-notifications {
    display: flex;
    align-items: center;
    gap: 40px;
}

.cu-topbar-item {
    width: 21.65px;
    height: 20px;
}

.cu-topbar-item img {
    width: 100%;
    height: 100%;
}

.cu-topbar-item:hover {
    cursor: pointer;
}

.topbar-family-options {
    background-color: #ffffff;
    border: 1px solid red;
    position: fixed;
    top: 75px;
    right: 1%;
    box-shadow: 2px 2px 8px 0px #00000026;
    z-index: 999;
}

.topbar-family-option {
    display: flex;

    align-items: center;
    gap: 10px;
    padding: 10px;
    width: 230px;
}

.topbar-family-option img {
    width: 30px;
    height: 30px;
}

@media (max-width: 600px) {
    .cu-top-bar-cufrontlogo {
        width: 42.5px;
        min-height: 23px;
    }

    .cu-top-bar-main {
        min-height: 55px;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slideUp {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-100%);
    }
}

.family-options-cont {
    background-color: #ffffff;
    position: fixed;

    width: 100%;
    margin: auto;
    top: 0px;
    left: -1px;
    padding: 18px;
    gap: 10px;
    z-index: 1000;
    animation: slideDown 0.5s ease forwards;
}

.family-options-cont.closed {
    animation: slideUp 0.5s ease forwards;
    top: 0;
}

.family-options-cont > div > div {
    padding: 10px;
    border-radius: 5px;
    width: 126px;
    height: 131px;
}
