.cu-screen-loader-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 45;
    background-color: rgba(0, 0, 0, 0.75);
}

.cu-screen-loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
}

.cu-screen-loader {
    width: 100px;
    aspect-ratio: 1;
    padding: 10px;
    box-sizing: border-box;
    display: grid;
    mix-blend-mode: darken;
}

.cu-screen-loader:before,
.cu-screen-loader:after {
    content: "";
    grid-area: 1/1;
    width: 40px;
    height: 40px;
    background: #c7eac9;
    animation: l7 2s infinite;
}

.cu-screen-loader:after {
    animation-delay: -1s;
}

@keyframes l7 {
    0% {
        transform: translate(0, 0);
    }
    25% {
        transform: translate(100%, 0);
    }
    50% {
        transform: translate(100%, 100%);
    }
    75% {
        transform: translate(0, 100%);
    }
    100% {
        transform: translate(0, 0);
    }
}
