.pdfViewer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: 999;
    display: flex;
    justify-content: flex-end;
    animation: slide-up 0.1s forwards;
}

::-webkit-scrollbar {
    display: none;
}
