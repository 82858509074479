/* MobileModal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 998;
}

body.modal-open {
    overflow: hidden;
}

.laptop-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    max-width: 600px;
    height: auto;
    max-height: 80%;
    z-index: 999;
}

.laptop-modal-content {
    width: 100%;
    background-color: white;
    /* border-top-left-radius: 20px;
    border-top-right-radius: 20px; */
    border-radius: 20px;
    min-height: 450px;
}

.modal-container-book-appointment {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    animation: slide-up 0.5s forwards;
}

.modal-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    /* height: 64%; */
    /* background-color: rgba(0, 0, 0, 0.5); */
    z-index: 999;
    display: flex;
    justify-content: flex-end;
    animation: slide-up 0.1s forwards;
}

@keyframes slide-up {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slide-right-to-left {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

/* Styles for modal container coming from right side of screen */
.modal-container-top-right {
    position: fixed;
    top: 0;
    right: 0;
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    z-index: 999;
    animation: slide-right-to-left 0.5s forwards;
}

.modal-container-top-right-modal-content {
    width: 100%;
    background-color: white;
    overflow: auto;
    /* border-top-left-radius: 20px; */
    /* border-top-right-radius: 20px; */
}

.modal-content {
    width: 100%;
    background-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.mobile-appointment-body {
    width: 90%;
    margin: auto;
}
.modal-header > h4 {
    font-weight: 700;
    color: #4b4b4b;
    line-height: 18.4px;
    margin-top: 15px;
}

.modal-header span {
    font-weight: 400;
    color: #4b4b4b;
    line-height: 18.4px;
}

.modal-body {
    /* margin-top: 45px; */
    color: black;
    font-weight: 400;
}

.modal-container-reschedule-appointment {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    /* height: calc(55% - 25px); */
    z-index: 999;
    display: flex;
    justify-content: flex-end;
    animation: slide-up 0.5s forwards;
}

.modal-container-edit {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    /* height: calc(67% - 25px); */
    z-index: 999;
    /* display: flex;
    justify-content: flex-end; */
    animation: slide-up 0.5s forwards;
}

.mobile-edit-detail-body {
    width: 90%;
    margin: auto;
    padding: 5px;
}

.mobile-edit-detail-body label {
    color: black;
    font-weight: 500;
    line-height: 20px;
}

.mobile-reschedule-body {
    width: 90%;
    margin: auto;
    padding: 5px;
}

.mobile-reschedule-body label {
    color: black;
    font-weight: 500;
    line-height: 20px;
}

@media only screen and (max-width: 767px) and (min-height: 800px) {
    /* .modal-body {
      margin-top: 80px;
    } */

    /* .modal-container-book-appointment {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: calc(85% - 30px);
      z-index: 999;
      display: flex;
      justify-content: flex-end;
      animation: slide-up 0.5s forwards;
    } */
}

@media only screen and (max-width: 450px) and (max-height: 790px) {
    /* .modal-container-book-appointment {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: calc(97% - 25px);
      z-index: 999;
      display: flex;
      justify-content: flex-end;
      animation: slide-up 0.5s forwards;
    } */

    .modal-container-reschedule-appointment {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        /* height: calc(67% - 25px); */
        z-index: 999;
        display: flex;
        justify-content: flex-end;
        animation: slide-up 0.5s forwards;
    }

    /* .modal-body {
      margin-top: 40px;
    } */

    .mobile-appointment-body > form > div {
        margin-top: 2px;
        width: 200;
    }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
    /* .modal-body {
      margin-top: 80px;
    } */

    #date-age-custom div {
        width: 150px !important;
    }

    .mobile-appointment-body {
        width: 90%;
        margin: auto;
    }

    /* .modal-container-book-appointment {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: fit-content;
      height: 82%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 999;
      display: flex;
      justify-content: flex-end;
      animation: slide-up 0.5s forwards;
    } */
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    /* Your CSS rules for tablet devices go here */

    /* .modal-container-book-appointment {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: fit-content;
      height: 65%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 999;
      display: flex;
      justify-content: flex-end;
      animation: slide-up 0.5s forwards;
    } */
}

@media only screen and (max-width: 450px) and (max-height: 790px) {
    .modal-container {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        /* height: 70%; */
        z-index: 999;
        display: flex;
        justify-content: flex-end;
        animation: slide-up 0.1s forwards;
    }

    .modal-container-reschedule-appointment {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        display: flex;
        justify-content: flex-end;
        animation: slide-up 0.5s forwards;
    }

    .modal-container-edit {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        animation: slide-up 0.5s forwards;
    }
    /* .mobile-appointment-body {
      width: 90%;
      margin: auto;
    } */
}
