.cu-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.75);
}

.cu-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 1rem;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 30;
    animation: slide-down 300ms ease-out forwards;
    min-width: 30%;
    min-height: 30%;
    max-height: 80vh;
    overflow-y: auto; /* Add overflow-y auto */
}

@keyframes slide-down {
    from {
        opacity: 0;
        transform: translateY(-3rem);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 880px) {
    .cu-modal {
        width: 80% !important;
    }
}

@media (max-width: 645px) {
    .cu-modal {
        width: 90% !important;
    }
}
