@keyframes slideInFromBottom {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.cu-visit-pre-cont {
    animation: slideInFromBottom 0.5s ease-out;
}

.cu-visit-body {
    height: calc(100dvh - 63px);
    overflow: auto;
    /* border: 1px solid red; */
}
