.cu-invoice-email-template {
    /* font-family: "Helvetica";
    font-style: normal; */
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 80%;
    height: 80%;
    padding: 13px 10px 0 10px;
    /* width: 10.5cm;
    height: 14.8cm; */
  }
  
  .cu-invoice-email-template::-webkit-scrollbar {
    display: none;
  }
  
  .cu-invoice-hospital-name {
    text-align: center;
    font-weight: 400;
    font-size: 32px;
    line-height: 30px;
    color: #000000;
  }
  
  .cu-invoice-hospital-address {
    display: flex;
    justify-content: center;
  }
  
  .cu-invoice-hospital-address div {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    width: 494px;
    text-align: center;
  }
  
  .cu-invoice-billing-type {
    border: 1px solid rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    text-align: center;
    padding: 8px 0 8px 0;
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
    color: #000000;
  }
  
  .cu-invoice-patient-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .cu-invoice-pdf-table {
    /* border: 1px solid #000000cc;
    border-bottom: none;
    border-right: none;
    border-radius: 3px; */
  }
  
  .cu-invoice-pdf-table table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    border: 1px solid #000000cc;
    /* border-radius: 3px; */
  }
  
  .cu-invoice-pdf-table td {
    padding: 5px 0 5px 3px;
    /* text-align: center; */
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    color: #000000;
    border-right: 1px solid #000000cc;
    border-bottom: 1px solid #000000cc;
  }
  
  .cu-invoice-pdf-table tr {
    /* border-bottom: 1px solid #000000cc; */
  }
  
  .cu-invoice-pdf-table th {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 13px;
    padding: 10px 3px 10px 3px;
    color: #000000;
    border-right: 1px solid #000000cc;
    border-bottom: 1px solid #000000cc;
    text-align: start;
  }
  