@media print {
    .print-content {
      border: 0.2px solid white;
    }
  
    @media print {
      .print-content {
        border: 0.2px solid white;
      }
    }
  }
  
  .cu-print-preview-vital-label {
    font-family: Helvetica;
    font-size: 11.5px;
    font-weight: 400;
    line-height: 13.8px;
    color: #404040;
  }
  
  .cu-print-preview-vital-value {
    font-family: Helvetica;
    font-size: 13px;
    font-weight: 400;
    line-height: 18.4px;
    color: #404040;
    display: flex;
    flex-direction: column;
  }
  