.cu-patient-outlet-cont {
    width: 100%;
    height: 100dvh;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
}

.cu-patient-navbar-cont {
    width: 100%;
    height: 105px;
    /* border: 1px solid green; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px;
    border-bottom: 1px solid #00000040;
}

.cu-p-logo {
    /* border: 1px solid red; */
    width: 203px;
    height: 100%;
}

.cu-p-logo > img {
    height: 100%;
    width: 100%;
}

.cu-patient-navigation {
    /* width: 695px; */
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 50%;
}

.cu-patient-nav-profile {
    /* width: 233px; */
    height: 100%;
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.cu-nav-login-profile {
    width: 30px;
    height: 30px;
    border: 1px solid black;
    border-radius: 50%;
    margin-right: 10px;
}

.cu-nav-login-name > div:first-child {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.4px;
}

.cu-nav-login-name > div:last-child {
    font-size: 10px;
    line-height: 11.5px;
    color: #000000b2;
}

.cu-patient-outlet-main {
    flex: 1;
    overflow-y: scroll;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* padding: 0 10px 0 10px; */
    /* border: 2px solid green; */
}

.cu-patient-outlet-main::-webkit-scrollbar {
    display: none;
}

.cu-patient-outlet-div {
    width: 70%;
    height: 100%;
    margin: 0 auto;
    /* padding: 0 16px; */
    /* border: 2px solid red; */
}

.cu-patient-bottom-bar-cont {
    width: 100%;
    height: 90px;
    /* border: 1px solid blue; */
}

.cu-patient-bottom-bar {
    width: 100%;
    height: 100%;
    padding: 2px 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.cu-bottom-bar-option {
    border-top: 2px solid;
}

.cu-bottom-bar-option-active {
    border: none;
}

.cu-patient-bottom-div {
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    flex-direction: column;
}

.cu-patient-bottom-div img {
    width: 26.29px;
    height: 26.29px;
}

.cu-patient-bottom-div > div {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 11px;
    color: #3f3f3f;
    margin-top: 8px;
}

@media (max-width: 1000px) {
    .cu-patient-outlet-div {
        width: 80%;
    }
}

@media (max-width: 900px) {
    .cu-patient-outlet-div {
        width: 90%;
    }
}

@media (max-width: 800px) {
    .cu-patient-outlet-div {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .cu-patient-bottom-bar-cont {
        height: 65px !important;
    }

    .cu-patient-bottom-div img {
        width: 22px;
        height: 23px;
    }
}
